<template>
  <div class="h-3px bg-bw-08 sticky top-18.5 md:top-19.5 lg:top-21.5 xl:top-22 z-200">
    <div class="h-full bg-primary w-10" :style="{ width: scrollPercentage + '%' }"></div>
  </div>
  <div class="responsive-padding">
    <section-header-content></section-header-content>
    <section-content></section-content>
  </div>
  <section-sign-up></section-sign-up>
  <div class="responsive-padding">
    <section-new-relate></section-new-relate>
    <section-new-latest></section-new-latest>
  </div>
</template>

<script lang="ts">
export default {
  name: 'new_id'
}
</script>

<script setup lang="ts">
import SectionHeaderContent from './components/SectionHeaderContent.vue'
import SectionContent from './components/SectionContent.vue'
import SectionSignUp from '../components/SectionSignUp.vue'
import SectionNewRelate from './components/SectionNewRelate.vue'
import SectionNewLatest from './components/SectionNewLatest.vue'
import {useGetLangCurrent} from '../../../stores/useLanguageMenu'

const {getNewsDetailBySlug} = useIZNewsDetail()

const pageHeight = ref(0)
const scrollPosition = ref(0)
const route = useRoute()
const _slugNewDetail = route.params.slug
const res = await getNewsDetailBySlug(route.params.langCode, useSeoFields('news'), {
  slug: _slugNewDetail,
  categorySlug: route.params.category
})

const updatePageHeight = () => {
  pageHeight.value = document.documentElement.scrollHeight - window.innerHeight
}

const updateScrollPosition = () => {
  scrollPosition.value = window.scrollY
}

const updateValues = () => {
  requestAnimationFrame(() => {
    updatePageHeight()
    updateScrollPosition()
  })
}
const scrollPercentage = computed(() => {
  return Math.round((scrollPosition.value / pageHeight.value) * 100)
})
useDirectusCollectionSeo('news', {
  ...res?.model,
  seo_detail: {
    ...res?.model?.seo_detail
  }
})

const store = useGetLangCurrent()

onMounted(() => {
  updateValues()

  window.addEventListener('scroll', updateValues)
  window.addEventListener('resize', updateValues)
  store.lang_switch = res?.model.lang_switch
  document.querySelector('html')?.classList.add('scroll-smooth')
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', updateValues)
  window.removeEventListener('resize', updateValues)
  document.querySelector('html')?.classList.remove('scroll-smooth')
})
</script>

<style scoped>
.responsive-padding {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1920px) {
  .responsive-padding {
    padding-left: 35rem;
    padding-right: 35rem;
  }
}
</style>
