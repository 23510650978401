<template>
  <section class="container relative mb-20" v-if="content">
    <div class="absolute top-5 bottom-0 -left-13 xl:block hidden">
      <list-icon-social class="sticky top-30 h-max" v-if="content"></list-icon-social>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-28 lg:min-h-103">
      <!-- MENU MOBILE -->
      <div v-show="showTableOfContents" class="lg:hidden block mb-10">
        <div class="relative inset-0 bg-white py-2 lg:pb-5">
          <div class="b-1 b-solid rounded px-4 py-2 bg-bw-04/10">
            <div
                @click="showTableOfContentsMobile = !showTableOfContentsMobile"
                class="flex items-center justify-between w-full gap-2 cursor-pointer"
            >
              <h2 class="flex-1 text-sm text-black font-[550] !text-lg">{{ $t('MAIN_CONTENT') }}</h2>
              <button
                  class="text-bw-15 w-7 h-7 flex flex-col items-center justify-center b-1 b-solid b-bw-04/70 rounded hover:bg-bw-04/20 duration-200"
              >
                <span class="i-ic:twotone-format-list-bulleted text-lg"></span>
              </button>
            </div>
            <div class="item-parent" :class="showTableOfContentsMobile ? 'activeList my-3 xl:my-5' : ''">
              <div>
                <div class="h-1px w-full bg-bw-20"></div>
                <div>
                  <table-of-content class="!text-15px" @listHeading="checkHeading"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="post-content lg:col-span-3" v-html="content" id="content_new"></div>

      <!-- MENU DESKTOP -->
      <div
          v-show="showTableOfContents"
          class="sticky top-30 rounded-5 border border-black/10 bg-white w-full p-8.5 pt-7 h-max lg:block hidden"
      >
        <div>
          <p class="text-xs xl:text-sm font-[550] text-black uppercase">
            {{ $t('TABLE_OF_CONTENTS') }}
          </p>
          <div class="h-1px w-full bg-bw-20 my-3 xl:my-5"></div>
          <div>
            <table-of-content
                class="wrapperSidebar w-full h-full overscroll-y-auto overflow-hidden lg:max-h-60vh xl:max-h-65vh custom-scrollbar"
                @list-heading="checkHeading"
            />
          </div>
        </div>
      </div>

      <div class="flex gap-x-3 mt-5 lg:col-span-2 col-span-1 items-center" v-if="tags && tags.length > 0">
        <p class="text-black/60 font-medium">Tag:</p>
        <p
            class="px-2 py-5px rounded-1.5 text-15px font-medium text-black/60 border border-black/60"
            v-for="tag in tags"
        >
          {{ convertTag(tag?.tags_id?.translations) }}
        </p>
      </div>

      <list-icon-social
          class="xl:hidden flex !flex-row border-t border-bw-8 pt-5 mt-5 lg:col-span-2 col-span-1"
      ></list-icon-social>

      <!--            <div class="lg:row-start-auto row-start-1">-->
      <!--                <div-->
      <!--                    class="sticky top-28 rounded-5 border border-black/10 bg-white overflow-hidden w-full p-8.5 pt-7 h-max"-->
      <!--                >-->
      <!--                    <p class="text-black font-[550] pb-5 uppercase border-b border-bw-20 leading-6 text-13px mb-3">-->
      <!--                        {{ t('TABLE_OF_CONTENTS') }}-->
      <!--                    </p>-->
      <!--                    <TableOfContentToc content-selector="#content_new" />-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'new_content'
}
</script>

<script setup lang="ts">
import {computed} from 'vue'
import TableOfContent from './TableOfContent.vue'
import ListIconSocial from './ListIconSocial.vue'

const {getNewsDetailBySlug} = useIZNewsDetail()
const {t} = useI18n()

const route = useRoute()
// const _idNewDetail = route.params.id.split('-').pop()
const _slugNewDetail = route.params.slug

const dataDetail: any = ref(null)

await getNewsDetailBySlug(route.params.langCode, useSeoFields('news'), {slug: _slugNewDetail, categorySlug: route.params.category})
    .then((response) => {
      dataDetail.value = response
    })
    .catch((error) => {
      console.error(error)
    })
const content = computed(() => {
  return dataDetail.value.model?.content
})

const tags = computed(() => {
  return dataDetail.value.model?.tags
})
const convertTag = (translations: any) => {
  let result = ''
  translations.map((item) => {
    if (useRoute().params.langCode == item?.languages_code) {
      //console.log(`output->item`, item?.title)
      result = item?.title
    } else {
      result = item?.title
    }
  })
  return result
}

const showTableOfContents = ref(false)
const showTableOfContentsMobile = ref(false)
const checkHeading = (data: any) => {
  showTableOfContents.value = !(data && data.length === 0);
}
</script>

<style scoped>
.post-content-news h1 {
  @apply md: text-33px text-bw-03 font-[550] md:leading-43px text-3xl leading-4xl mb-8;
}

.post-content-news h2 {
  @apply mb-4.5 text-bw-03 text-19px font-[550] leading-6;
}

.post-content-news h3 {
  @apply mb-4.5 text-bw-03 text-base font-[550] leading-6;
}

.post-content-news h4 {
  @apply mb-4.5 text-bw-03 text-base font-[550] leading-6;
}

.post-content-news p {
  @apply text-black/60 text-base leading-7 mb-9.5;
}

.post-content-news ul {
  @apply flex flex-col gap-1;
}

.post-content-news ul li {
  @apply text-black/60 font-[550] leading-10 text-base !list-disc;
}

.post-content-news img {
  @apply rounded-5 w-full object-cover;
}

.post-content-news a {
  @apply !text-primary;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.wrapperSidebar:hover {
  &::-webkit-scrollbar-thumb {
    background: #d0d0d0;
  }

  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: #f6f6f6; /* Optional: just make scrollbar invisible */
  }
}
</style>
